@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Jua&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Jua&display=swap');

body {
  margin: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F7F7F7;
  color: #ffffff;
}

.kiosk-screen-touch {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Do Hyeon", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
  cursor: pointer;
  font-style: normal;
}

.hospital-title-group {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.hospital-title-top {
  font-size: calc(4.6875rem + 2.5vmin);
  font-weight: bold;
  opacity: 0;
  animation: reveal-fast 2.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.hospital-title-bottom {
  font-size: calc(2.5rem + 2.5vmin);
  font-weight: bold;
  margin-bottom: 2.1875rem;
  opacity: 0;
  animation: reveal-fast 3.05s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.kiosk-screen-touch p {
  font-size: calc(1rem + 2.5vmin);
  opacity: 0;
  animation: reveal-slow 2.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  background-color: #0c82ec;
  color: #ffffff;
  padding: 0.9375rem 1.875rem;
  border-radius: 1.5625rem;
  text-align: center;
  margin-top: 1.875rem;
  box-shadow: 0px 0.25rem 0.375rem rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.kiosk-screen-touch p:hover {
  background-color: #0b70ca;
}

@keyframes reveal-fast {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes reveal-slow {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}



body {
  margin: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F7F7F7;
  color: #ffffff;
}

.kiosk-screen-touch {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Do Hyeon", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: #4a4a4a;
  cursor: pointer;
  font-style: normal;
}

.hospital-title-group {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.hospital-title-top {
  font-size: calc(4.6875rem + 2.5vmin);
  font-weight: bold;
  opacity: 0;
  animation: reveal-fast 2.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.hospital-title-bottom {
  font-size: calc(2.5rem + 2.5vmin);
  font-weight: bold;
  margin-bottom: 2.1875rem;
  opacity: 0;
  animation: reveal-fast 3.05s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.kiosk-screen-touch p {
  font-size: calc(1rem + 2.5vmin);
  opacity: 0;
  animation: reveal-slow 2.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  background-color: #0c82ec;
  color: #ffffff;
  padding: 0.9375rem 1.875rem;
  border-radius: 1.5625rem;
  text-align: center;
  margin-top: 1.875rem;
  box-shadow: 0px 0.25rem 0.375rem rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.kiosk-screen-touch p:hover {
  background-color: #0b70ca;
}

@keyframes reveal-fast {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes reveal-slow {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
